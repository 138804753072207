import { IonCol, IonRow } from '@ionic/react';
import React from 'react';

import BasePage from './BasePage';
import S from './TextPage.styles';

interface Props {
  children: React.ReactNode;
}

const TextPage: React.FC<Props> = ({ children }) => (
  <BasePage>
    <IonRow>
      <IonCol sizeXl="8" sizeLg="8" offsetXl="2" offsetLg="2" sizeMd="10" offsetMd="1" sizeSm="12">
        <S.Content>{children}</S.Content>
      </IonCol>
    </IonRow>
  </BasePage>
);

export default TextPage;

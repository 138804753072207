import { IonApp, IonPage, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PostHogProvider } from 'posthog-js/react';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { environment } from '@env';

import SideMenu from './components/SideMenu';
import { Routes } from './config/routes';
import AuthProvider from './context/AuthProvider';
import AboutUsPage from './pages/AboutUsPage';
import AuthPage from './pages/AuthPage';
import ChatPage from './pages/ChatPage';
import DashboardPage from './pages/DashboardPage';
import HomePage from './pages/HomePage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import PropertyCreatePage from './pages/PropertyCreatePage';
import TermsOfServicePage from './pages/TermsOfServicePage';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
// eslint-disable-next-line import/order
import '@ionic/react/css/normalize.css';
// eslint-disable-next-line import/order
import '@ionic/react/css/structure.css';
// eslint-disable-next-line import/order
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
// eslint-disable-next-line import/order
import '@ionic/react/css/display.css';
// eslint-disable-next-line import/order
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';

/* Global Styles */
import GlobalStyles from './styles';

const options = {
  api_host: environment.posthogUrl
};

setupIonicReact();

const queryClient = new QueryClient();

const App: React.FC = () => (
  <PostHogProvider apiKey={environment.posthogKey} options={options}>
    <QueryClientProvider client={queryClient}>
      <GlobalStyles />
      <IonApp>
        <IonReactRouter>
          <AuthProvider>
            <SideMenu />

            <IonPage id="main-content">
              <Switch>
                <Route exact path={Routes.home} component={HomePage} />
                <Route exact path={Routes.login} component={AuthPage} />
                <Route exact path={Routes.signup} component={AuthPage} />
                <Route exact path={Routes.dashboard} component={DashboardPage} />
                <Route exact path={Routes.properties}>
                  <Redirect to={Routes.dashboard} />
                </Route>
                <Route exact path={Routes.createProperty} component={PropertyCreatePage} />
                <Route exact path={Routes.chat} component={ChatPage} />
                <Route exact path={Routes.aboutUs} component={AboutUsPage} />
                <Route exact path={Routes.privacyPolicy} component={PrivacyPolicyPage} />
                <Route exact path={Routes.termsOfService} component={TermsOfServicePage} />
                <Route>
                  <Redirect to={Routes.home} />
                </Route>
              </Switch>
            </IonPage>
          </AuthProvider>
        </IonReactRouter>
      </IonApp>
    </QueryClientProvider>
  </PostHogProvider>
);

export default App;

import { css } from 'styled-components';

const generateUtils = () => {
  let styles = '';

  for (let i = -64; i <= 64; i++) {
    styles += `
      .pt-${i} { padding-top: ${i}px !important; }
      .pr-${i} { padding-right: ${i}px !important; }
      .pb-${i} { padding-bottom: ${i}px !important; }
      .pl-${i} { padding-left: ${i}px !important; }
      .mt-${i} { margin-top: ${i}px !important; }
      .mr-${i} { margin-right: ${i}px !important; }
      .mb-${i} { margin-bottom: ${i}px !important; }
      .ml-${i} { margin-left: ${i}px !important; }
    `;
  }

  return styles;
};

const utilsCss = css`
  ${generateUtils()}

  .hidden {
    visibility: hidden;
  }
`;

export default utilsCss;

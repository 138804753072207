import { IonRow } from '@ionic/react';
import styled from 'styled-components';

const HeaderIonRow = styled(IonRow)`
  margin-bottom: 5rem;
`;

export default {
  HeaderIonRow,
};

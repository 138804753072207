import { IonButtons, IonMenuButton } from '@ionic/react';
import React from 'react';

import S from './Header.styles';
import Menu from './Menu';
import TerraiLogo from './TerraiLogo';


interface HeaderProps {
  logoOnly?: boolean;
}

const Header: React.FC<HeaderProps> = ({ logoOnly = false }) => (
  <S.IonHeader className="ion-no-border">
    <S.IonToolbar>
      <IonButtons slot="start" className="ion-hide-md-up">
        <IonMenuButton color="darkgray" />
      </IonButtons>

      <div className="ion-float-md-start ion-padding-start ion-margin-start">
        <TerraiLogo />
      </div>
      {!logoOnly && (
        <IonButtons slot="end" className="ion-hide-md-down">
          <Menu orientation="horizontal" />
        </IonButtons>
      )}
      {/* <IonTitle className={`${styles.title} ion-text-center`}>Terr.ai</IonTitle> */}
    </S.IonToolbar>
  </S.IonHeader>
);

export default Header;

import React, { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Routes } from '../config/routes';
import { useAuth } from '../context/AuthProvider';

import S from './Menu.styles';


interface MenuItem {
  text: string;
  link?: string;
  shape?: string;
  fill?: string;
  isStartChat?: boolean;
  onClick?: () => void;
}

interface MenuProps {
  orientation: 'horizontal' | 'vertical';
}

const Menu: React.FC<MenuProps> = ({ orientation }) => {
  const history = useHistory();
  const location = useLocation();
  const { user, signOut } = useAuth();

  const handleLogout = useCallback(async () => {
    await signOut();

    history.push(Routes.login); // Redirect to login page after logout
  }, [history, signOut]);

  const menuItems: MenuItem[] = useMemo(() => {
    if (!user || user.is_anonymous)
      return [
        { text: 'About Us', link: Routes.aboutUs },
        { text: 'Login', link: Routes.login },
        { text: 'Start a Chat', link: Routes.chat, isStartChat: true },
      ];

    return [
      { text: 'Dashboard', link: Routes.dashboard },
      { text: 'Chat', link: Routes.chat },
      { text: 'Logout', onClick: handleLogout },
    ];
  }, [user, handleLogout]);

  return menuItems.map((item, index) => (
    <S.IonButton
      key={item.text}
      $isActive={item.link === location.pathname && location.pathname !== Routes.chat}
      $isStartChat={item.isStartChat}
      routerLink={item.link}
      fill="clear"
      shape="round"
      expand={orientation === 'vertical' ? 'full' : undefined}
      onClick={item.onClick}
    >
      {item.text}
    </S.IonButton>
  ));
};

export default Menu;

import { createGlobalStyle } from 'styled-components';

import ionicCss from './ionic';
import typographyCss from './typography';
import utilsCss from './utils';
import variablesFigma from './variablesFigma';
import variablesIonic from './variablesIonic';

const GlobalStyles = createGlobalStyle`
  ${variablesFigma}
  ${variablesIonic}

  body {
    font-family: 'Matter', sans-serif;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.01em;
    color: var(--figma-color-darkgray-300);
  }

  a {
    color: var(--figma-color-darkgray-300);

    &:hover {
      color: var(--figma-color-darkgray-200);
    }
  }

  ${typographyCss}
  ${ionicCss}
  ${utilsCss}
`;

export default GlobalStyles;

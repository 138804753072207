import * as Sentry from '@sentry/react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { DefaultGenerics, StreamChat } from 'stream-chat';
import { Channel as StreamChannel } from 'stream-chat/dist/types/channel';
import { Message as StreamMessage } from 'stream-chat/src/types';
import { Channel, Chat, MessageInput, MessageList, Window } from 'stream-chat-react';

import { Routes } from '../../config/routes';
import { useAuth } from '../../context/AuthProvider';
import { VERSION } from '../../environments/version';
import { Address, addressToText, getIsAddressEnabled } from '../../utils/address';
import AddressSelect from '../address/AddressSelect';

import ChatBubble from './ChatBubble';
import CreateAccountPrompt from './CreateAccountPrompt';
import LLMChatBubble from './LLMChatBubble';
import StarterQuestions from './StarterQuestions';
import S from './StreamChatChat.styles';
import TerraiMessageUI from './TerraiMessageUI';
import TypingIndicator from './TypingIndicator';


const messageLimit = 5;

interface Props {
  address: Address | null;
  onSaveAddress: (address: Address) => void;
  client: StreamChat;
  channel: StreamChannel<DefaultGenerics>;
}

const StreamChatChat: React.FC<Props> = ({ address, onSaveAddress, client, channel }) => {
  const [isInForcedEditAddressMode, setIsInForcedEditAddressMode] = useState(false);
  const questionToAsk = useRef<string | null>(window.history.state?.state?.question ?? null);

  const [manualMessageCount, setManualMessageCount] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(() => {
      const count = channel.state.messages.filter(msg => msg.user?.role === 'user').length;
      setManualMessageCount(count);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [channel]);

  const { user } = useAuth();

  const isWaitlisted = useMemo(() => !!address && !getIsAddressEnabled(address), [address]);

  const hasReachedMessageLimit = useMemo(() => {
    if (!user) return true;

    if (!user.is_anonymous) return false;

    return manualMessageCount > messageLimit;
  }, [user, manualMessageCount]);

  const doSendMessageRequest = useCallback(
    async (cbChannel: StreamChannel<DefaultGenerics>, message: StreamMessage<DefaultGenerics>) => {
      const activeSpan = Sentry.getActiveSpan();
      const rootSpan = activeSpan ? Sentry.getRootSpan(activeSpan) : undefined;

      // Create `sentry-trace` header
      const sentryTrace = rootSpan ? Sentry.spanToTraceHeader(rootSpan) : undefined;

      // Create `baggage` header
      const sentryBaggage = rootSpan ? Sentry.spanToBaggageHeader(rootSpan) : undefined;

      const modifiedMessage = {
        ...message,
        sentryTrace,
        sentryBaggage,
        terrai_env: VERSION.subenv
      };

      // Send the message via Stream Chat
      return cbChannel.sendMessage(modifiedMessage);
    },
    []
  );

  const isAskingForAddress = useMemo(() => {
    if (isInForcedEditAddressMode) return true;

    return !address;
  }, [isInForcedEditAddressMode, address]);

  const onAddressSelect = useCallback(
    (selectedAddress: Address) => {
      setIsInForcedEditAddressMode(false);

      onSaveAddress(selectedAddress);
    },
    [onSaveAddress]
  );

  useEffect(() => {
    if (!user) return;

    if (!channel) return;

    if (hasReachedMessageLimit) return;

    if (!questionToAsk.current) return;

    channel.sendMessage({
      text: questionToAsk.current,
      user_id: user.id
    });

    questionToAsk.current = null;
    // Make sure to clear state,
    // so the question isn't asked again on reload.
    window.history.replaceState({}, '');

    return () => {
      questionToAsk.current = null;
      window.history.replaceState({}, '');
    };
  }, [user, channel, hasReachedMessageLimit, questionToAsk]);

  return (
    <S.Container>
      <Chat client={client}>
        <Channel
          channel={channel}
          doSendMessageRequest={doSendMessageRequest}
          TypingIndicator={TypingIndicator}
        >
          <Window>
            {isAskingForAddress ? (
              <div>
                <LLMChatBubble>
                  For personalized information, enter your address. Your data stays private—we never
                  share it. Enjoy exploring!
                </LLMChatBubble>

                <AddressSelect onAddressSelect={onAddressSelect} />
              </div>
            ) : (
              <div className="ml-16">
                <div>
                  <LLMChatBubble>
                    For personalized information, enter your address. Your data stays private—we
                    never share it. Enjoy exploring!
                  </LLMChatBubble>

                  {!!address && (
                    <div className="str-chat__list">
                      <ChatBubble>{addressToText(address)}</ChatBubble>
                    </div>
                  )}

                  {!!user && user.is_anonymous && (
                    <LLMChatBubble>
                      To save your information, <Link to={Routes.signup}>create an account</Link>.
                    </LLMChatBubble>
                  )}

                  {!isWaitlisted && (
                    <>
                      <StarterQuestions channel={channel} />

                      <MessageList
                        Message={TerraiMessageUI}
                        disableDateSeparator
                        disableQuotedMessages
                        messageActions={[]}
                        hideNewMessageSeparator
                      />

                      {hasReachedMessageLimit ? (
                        <>
                          <LLMChatBubble>
                            You have reached limit of messages for unregistered users.
                          </LLMChatBubble>

                          <CreateAccountPrompt />
                        </>
                      ) : (
                        <MessageInput />
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
          </Window>
        </Channel>
      </Chat>
    </S.Container>
  );
};

export default StreamChatChat;

import { IonIcon, IonImg } from '@ionic/react';
import { thumbsDownOutline, thumbsDownSharp, thumbsUpOutline, thumbsUpSharp } from 'ionicons/icons';
import React from 'react';

import S from './LLMChatBubble.styles';

export type Rating = 'positive' | 'negative';

type Props = {
  children: React.ReactNode;
} & (
  | {
      isRateable?: never;
      rating?: never;
      onRatingChange?: never;
    }
  | {
      isRateable: true;
      rating?: Rating;
      onRatingChange: (rating: Rating) => void;
    }
);

const LLMChatBubble: React.FC<Props> = ({ children, isRateable, rating, onRatingChange }) => (
  <S.IonList>
    <S.IonItem lines="none">
      <S.IonAvatar slot="start">
        <IonImg src="./assets/images/terri-logo.svg" alt="Terri" />
      </S.IonAvatar>

      <div>
        {children}

        {isRateable && (
          <S.RatingContainer>
            <S.RatingIonButton
              onClick={() => onRatingChange('positive')}
              disabled={rating === 'positive'}
              size="small"
              color="neutral"
            >
              <IonIcon
                slot="icon-only"
                color="darkgray"
                icon={rating === 'positive' ? thumbsUpSharp : thumbsUpOutline}
              />
            </S.RatingIonButton>

            <S.RatingIonButton
              onClick={() => onRatingChange('negative')}
              disabled={rating === 'negative'}
              size="small"
              color="neutral"
            >
              <IonIcon
                slot="icon-only"
                color="darkgray"
                icon={rating === 'negative' ? thumbsDownSharp : thumbsDownOutline}
              />
            </S.RatingIonButton>
          </S.RatingContainer>
        )}
      </div>
    </S.IonItem>
  </S.IonList>
);

export default LLMChatBubble;

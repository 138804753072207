import styled from 'styled-components';

const Input = styled.input`
  background: var(--figma-color-neutral-200);
  font-size: 1rem;
  height: 3em;
  padding: 1em 1.25em;
  width: 100%;
  flex: 0 0 100%;
  border-radius: 0.75em;
  border: none;

  &:hover:not(:focus) {
    background: var(--figma-color-neutral-300);
  }

  &:focus {
    outline: 1px solid var(--figma-color-darkgray-300);
  }
`;

export default {
  Input,
};

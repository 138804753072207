import { IonImg } from '@ionic/react';
import styled from 'styled-components';

import { breakpointMdDown, breakpointSmDown } from '../styles/breakpoints';

const H1 = styled.h1`
  margin-top: 7rem;
`;

const Suggestions = styled.div`
  border-radius: var(--figma-border-radius-card);
  background: var(--figma-color-neutral-200);
  padding: 1.25rem;
  margin-top: 3rem;
`;

const FlexContainer = styled.nav`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: baseline;
  gap: 0.25rem;
`;

const TopicsHeader = styled.h3`
  margin-bottom: auto;
  padding-bottom: 2rem;
  color: var(--ion-color-dark);
  @media screen and (max-width: ${breakpointMdDown}) {
    padding-bottom: 1rem;
  }
`;

const TopicsContainer = styled(FlexContainer)`
  @media screen and (max-width: ${breakpointMdDown}) {
    flex-direction: row;
    margin-bottom: 1rem;
  }
`;

const DividerImg = styled(IonImg)`
  &::part(image) {
    width: auto;
  }
`;

const SuggestionsContainer = styled(FlexContainer)`
  @media screen and (max-width: ${breakpointSmDown}) {
    align-items: stretch;
  }
`;

export default {
  H1,
  Suggestions,
  FlexContainer,
  TopicsHeader,
  TopicsContainer,
  DividerImg,
  SuggestionsContainer,
};

import { css } from 'styled-components';

import { h1, h2, h3, p } from './typography';

const ionicCss = css`
  ion-button {
    --border-radius: var(--figma-border-radius-button);
    --border-width: var(--figma-border-width-button);
    --padding-bottom: 0.625rem;
    --padding-end: 1rem;
    --padding-start: 1rem;
    --padding-top: 0.625rem;
    font-family: 'MatterMono';
    text-transform: uppercase;
    font-size: 0.75rem;
    margin: 0 0.25rem 0.25rem 0;
    text-wrap: wrap;

    &::part(native) {
      box-shadow: none;
      --border-width: 1.5px;
    }

    &[fill='outline'].ion-color-neutral::part(native) {
      color: var(--ion-color-contrast);
    }

    &[size='small'] {
      --padding-bottom: 0.375rem;
      --padding-end: 0.75rem;
      --padding-start: 0.75rem;
      --padding-top: 0.375rem;
    }

    &[size='large'] {
      --padding-bottom: 1.125rem;
      --padding-end: 1.25rem;
      --padding-start: 1.25rem;
      --padding-top: 1.125rem;
    }
  }

  ion-card {
    border-radius: var(--figma-border-radius-card);
    box-shadow: 0px 24px 64px rgba(0, 0, 0, 0.16);

    ion-card-header {
      padding: 1.25rem;

      ion-card-subtitle {
        font-family: 'MatterMono', monospace;
        font-weight: 500;
        --color: var(--figma-color-darkgray-300);
        text-transform: uppercase;
        font-size: 0.75rem;
        letter-spacing: 0.06em;
        line-height: 1.5;
      }
    }

    ion-card-content {
      padding: 1.25rem !important;
      font-size: 1rem !important;
      letter-spacing: -0.01em;
      --color: var(--figma-color-darkgray-100);
      line-height: 1.5;
    }

    ion-list {
      --background: #fff;
      --ion-item-background: #ffffff;
    }
  }

  ion-checkbox {
    --border-radius: var(--figma-border-radius-button);
    --checkbox-background: var(--figma-color=white);
    --border-color: var(--figma-color-neutral-300);
    --checkbox-background-checked: var(--figma-color-darkgray-300);
    --border-color-checked: var(--figma-color-darkgray-300);
    --size: 1.25rem;
    --checkbox-icon-size: 0.5rem;
    --stroke-dasharray: 25;
  }

  .card-content-md {
    p {
      ${p}
    }

    h1 {
      ${h1}
    }

    h2 {
      ${h2}
    }

    h3 {
      ${h3}
    }
  }

  ion-content::part(background) {
    --background: var(--figma-color-neutral-200);
  }

  ion-menu {
    --ion-background-color: #ffffff; /* Customize item background color if needed */
  }

  ion-input[fill='solid'] {
    border-radius: 9999px;
  }

  ion-progress-bar {
    --background: var(--figma-color-neutral-200);
    --progress-background: var(--figma-color-darkgray-300);
    height: 2px;
  }

  ion-skeleton-text {
    height: 1em;
  }
`;

export default ionicCss;

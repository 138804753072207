import { IonContent, IonMenuToggle } from '@ionic/react';
import React from 'react';

import Menu from './Menu';
import S from './SideMenu.styles';

const SideMenu: React.FC = () => (
  <S.IonMenu contentId="main-content">
    <IonContent className="ion-padding">
      <IonMenuToggle autoHide>
        <Menu orientation="vertical" />
      </IonMenuToggle>
    </IonContent>
  </S.IonMenu>
);

export default SideMenu;

import styled from 'styled-components';

const H1 = styled.h1`
  margin-top: 7rem;
`;

const ChatContainer = styled.div`
  margin-top: 1rem;
  padding-bottom: 3rem;
`;

const ChatFooter = styled.footer`
  background-color: var(--figma-color-neutral-100);
  border-top: 1px solid var(--figma-color-neutral-200);
  padding: 0.625rem 1.25rem;
  text-align: center;
  position: fixed; /* Fix the footer at the bottom */
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;

  p {
    margin: 0;
    line-height: 1;
  }
`;

export default {
  H1,
  ChatContainer,
  ChatFooter,
};

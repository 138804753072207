import { IonAvatar, IonButton, IonItem, IonList } from '@ionic/react';
import styled from 'styled-components';

const StyledIonList = styled(IonList)`
  background: transparent;
`;

const StyledIonItem = styled(IonItem)`
  --background: transparent;
  margin-bottom: 0.5rem;
  color: var(--figma-color-darkgray-300);
`;

const StyledIonAvatar = styled(IonAvatar)`
  --border-radius: 6px;
  align-self: flex-start;
`;

const RatingContainer = styled.div`
  display: flex;
  margin-top: 0.25rem;
`;

const RatingIonButton = styled(IonButton)`
  margin: 0;
  margin-left: -0.5rem;

  &::part(native) {
    background: transparent;
    font-size: 0.7em;
  }

  &:not(:first-child)&::part(native) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &:not(:last-child)&::part(native) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export default {
  IonList: StyledIonList,
  IonItem: StyledIonItem,
  IonAvatar: StyledIonAvatar,
  RatingContainer,
  RatingIonButton,
};

import styled from 'styled-components';

const Content = styled.main`
  h1 {
    text-transform: uppercase;
  }

  p {
    color: var(--figma-color-ocean-300);
    line-height: 1.5;
  }

  h2,
  h3,
  h4,
  h5 {
    margin-top: 1em;
  }

  ul,
  ol {
    color: var(--figma-color-ocean-300);

    li {
      line-height: 1.5;
      margin-bottom: 0.5em;
    }
  }

  em {
    color: var(--figma-color-darkgray-300);
    font-weight: bolder;
  }
`;

export default {
  Content,
};

import mapboxgl from 'mapbox-gl';

type MapboxMap = InstanceType<typeof mapboxgl.Map>;

export const pulsingDots = (size: number, map: MapboxMap) => ({
  width: size,
  height: size,
  data: new Uint8Array(size * size * 4),
  context: null as CanvasRenderingContext2D | null, // Add this line
  onAdd() {
    const canvas = document.createElement('canvas');
    canvas.width = this.width;
    canvas.height = this.height;
    this.context = canvas.getContext('2d');
  },
  render() {
    const duration = 1000;
    const t = (performance.now() % duration) / duration;
    const radius = (size / 2) * 0.3;
    const outerRadius = (size / 2) * 0.7 * t + radius;
    const { context } = this;
    if (context) {
      // Add this check
      context.clearRect(0, 0, this.width, this.height);
      context.beginPath();
      context.arc(this.width / 2, this.height / 2, outerRadius, 0, Math.PI * 2);
      context.fillStyle = `rgba(255, 200, 200, ${1 - t})`;
      context.fill();
      context.beginPath();
      context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
      context.fillStyle = 'rgba(255, 100, 100, 1)';
      context.strokeStyle = 'white';
      context.lineWidth = 2 + 4 * (1 - t);
      context.fill();
      context.stroke();
      this.data = new Uint8Array(
        context.getImageData(0, 0, this.width, this.height).data.buffer
      );
      map.triggerRepaint();
      return true;
    }
  }
});

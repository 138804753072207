import { IonCol, IonContent, IonGrid, IonPage } from '@ionic/react';
import React from 'react';

import ContentGradient from '../../components/ContentGradient';
import Header from '../../components/Header';

import S from './BasePage.styles';


interface Props {
  children: React.ReactNode;
}

const BasePage: React.FC<Props> = ({ children }) => (
  <IonPage>
    <IonContent>
      <ContentGradient />

      <IonGrid>
        <S.HeaderIonRow>
          <IonCol>
            <Header />
          </IonCol>
        </S.HeaderIonRow>

        {children}
      </IonGrid>
    </IonContent>
  </IonPage>
);

export default BasePage;

import { IonCol, IonProgressBar, IonRow } from '@ionic/react';
import React, { useEffect, useMemo } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import StreamChatInitializer from '../components/stream-chat/StreamChatInitializer';
import { Routes } from '../config/routes';
import { useAuth } from '../context/AuthProvider';

import S from './ChatPage.styles';
import BasePage from './common/BasePage';

export interface ChatRouteState {
  question?: string;
  isUnauthenticatedExperience?: boolean;
}

// eslint-disable-next-line no-shadow
enum AuthStatus {
  authenticated,
  loginRequired,
  processing,
}

const ChatPage: React.FC = () => {
  const location = useLocation<ChatRouteState>();

  const { isAuthenticated, shouldHaveAuthToken, signInAnonymously } = useAuth();

  useEffect(() => {
    if (isAuthenticated) return;

    if (!location.state?.isUnauthenticatedExperience) return;

    // Make sure that we aren't trying to create an anonymous user,
    // before authenticating as an existing user.
    if (!shouldHaveAuthToken) signInAnonymously();
  }, [location.state, isAuthenticated, shouldHaveAuthToken, signInAnonymously]);

  const authStatus: AuthStatus = useMemo(() => {
    if (isAuthenticated) return AuthStatus.authenticated;

    if (shouldHaveAuthToken) return AuthStatus.processing;

    if (!location.state?.isUnauthenticatedExperience) return AuthStatus.loginRequired;

    return AuthStatus.processing;
  }, [isAuthenticated, shouldHaveAuthToken, location.state]);

  if (authStatus === AuthStatus.loginRequired) {
    return <Redirect to={Routes.login} />;
  }

  return (
    <BasePage>
      <IonRow>
        <IonCol
          sizeXl="8"
          sizeLg="8"
          offsetXl="2"
          offsetLg="2"
          sizeMd="10"
          offsetMd="1"
          sizeSm="12"
        >
          {authStatus === AuthStatus.processing ? (
            <IonProgressBar type="indeterminate" />
          ) : (
            <>
              <S.H1>
                Hi there, I'm Terri!
                <br />
                Your go-to guide for everything in your community.
                <br />
                Ask me a question. I'm here to help!
              </S.H1>

              <S.ChatContainer>
                <StreamChatInitializer />
              </S.ChatContainer>

              <S.ChatFooter>
                <p>
                  <small>Do not include personal or sensitive information in your message.</small>
                </p>
              </S.ChatFooter>
            </>
          )}
        </IonCol>
      </IonRow>
    </BasePage>
  );
};

export default ChatPage;

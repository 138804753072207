import styled from 'styled-components';

import ContentGradientStyles from '../components/ContentGradient.styles';
import { breakpointMdUp } from '../styles/breakpoints';

const Container = styled.div`
  margin-top: -4.5rem;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
`;

const Section = styled.div`
  display: flex;
  flex: 0 0 50%;

  @media screen and (max-width: ${breakpointMdUp}) {
    flex: 0 0 100%;
  }
`;

const SectionContent = styled.div`
  margin: auto auto;
  width: 100%;
  max-width: 26.25rem;
  padding: 3rem 1rem;
`;

const AuthSection = styled(Section)`
  background-color: var(--figma-color-white);

  h2 {
    margin-bottom: 4rem;

    @media screen and (max-width: ${breakpointMdUp}) {
      margin-top: 4rem;
    }
  }
`;

const AboutSection = styled(Section)`
  position: relative;

  ${SectionContent} {
    max-width: 31.75rem;
  }

  ${ContentGradientStyles.Container} {
    position: absolute;
    top: -20vw;
  }

  ion-button {
    margin-bottom: 0.5rem;
    margin-left: 2rem;
    opacity: 1 !important;
  }
`;

const FormWrapper = styled.div`
  ion-button {
    margin-bottom: 0.5rem;
  }
`;

const InputWrapper = styled.div`
  margin-bottom: 0.5rem;
`;

const PasswordInputWrapper = styled(InputWrapper)`
  margin-bottom: 1.5rem;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 1.5rem 0;
`;

const OrText = styled.p`
  text-align: center;
  margin: 2rem 0;
  opacity: 0.5;
`;

const Footer = styled.div`
  text-align: center;
  margin-top: 2rem;
  font-size: 0.7rem;

  a {
    text-decoration: none;
    margin: 0 1rem;
  }
`;

export default {
  Container,
  SectionContent,
  AuthSection,
  AboutSection,
  FormWrapper,
  InputWrapper,
  PasswordInputWrapper,
  CheckboxWrapper,
  OrText,
  Footer,
};

import styled from 'styled-components';

const Container = styled.div`
  height: 100%;

  .str-chat {
    .str-chat__list .str-chat__message-list-scroll {
      padding: 0;
    }
    .str-chat__empty-channel {
      display: none;
    }
    .str-chat__message-input .str-chat__message-input-inner .str-chat__file-input-container {
      display: none;
    }
  }

  .str-chat__ul li {
    padding-left: 0px;
  }

  .str-chat__file-input-container {
    display: none;
  }

  .str-chat__message-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;

    min-width: 192px;
    min-height: 48px;

    background: #f9f5f3;
    backdrop-filter: blur(34px);
    border-radius: 100px;

    flex: none;
    order: 2;
    flex-grow: 0;
    .str-chat__message-textarea-container {
      border: none;
      font-family: 'Matter';
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 150%;

      letter-spacing: -0.01em;
      color: #1b353c;
      background: linear-gradient(122.3deg, #756f6c 0.52%, #f5f1ef 132.61%);
      background-clip: text;
      text-fill-color: #1b353c;
    }
  }
}

.str-chat {
  --str-chat__message-bubble-background-color: rgba(255, 255, 255, 0.6);
  --str-chat__background-color: none;

  .str-chat__typing-indicator {
    position: relative;
  }

  .str-chat__message-simple-name {
    display: none;
  }

  .str-chat__container {
    display: flex;
    flex-direction: column;
    height: 100%; /* Ensure container takes full height */
  }

  .str-chat__message--other {
    .str-chat__message-simple-timestamp {
      margin-left: 1rem;
    }

    .str-chat__message-bubble {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 8px;
      background: transparent;

      p {
        font-family: 'Matter';
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #1b353c;
      }
    }
  }

  .str-chat__message .str-chat__message-sender-avatar .str-chat__avatar-image {
    border-radius: 1rem;
  }

  .str-chat__li.str-chat__li--single .str-chat__message--me {
    display: flex !important;
    justify-content: flex-end !important;

    .str-chat__message-bubble {
      background: rgba(27, 48, 60, 0.08);
      border-radius: 16px;
      min-height: 35px;

      font-family: 'Matter';
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 150%;
      letter-spacing: -0.01em;

      p {
        color: #1b353c;
      }
    }

    .str-chat__message-data {
      display: none;
    }
  }

  .str-chat__message--me:hover {
    transform: none !important;
    transition: none !important;
  }

  .str-chat__main-panel .str-chat__ul .str-chat__li:first-of-type {
    padding-top: 0;
  }

  .str-chat__message .str-chat__message-sender-avatar {
    width: 40px !important;
    height: 40px !important;
    margin-bottom: -2px;
    .str-chat__avatar-image {
      width: 40px !important;
      height: 40px !important;
    }
  }

  .str-chat__message.str-chat__message--me,
  .str-chat__quoted-message-preview.str-chat__message--me {
    grid-template-areas:
      'avatar message'
      '. replies'
      '. translation-notice'
      '. custom-metadata'
      '. metadata';
    column-gap: var(--str-chat__spacing-2);
    grid-template-columns: auto 1fr;
    justify-items: flex-start;

    justify-content: flex-start;
    align-items: flex-start;

    .str-chat__message-inner {
      grid-template-areas:
        'reactions reactions'
        'message-bubble options';
      margin-inline-start: 0;
      margin-inline-end: 16px;
    }
  }
`;

export default {
  Container,
};

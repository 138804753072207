import React, { useContext, useState } from 'react';
import { MessageText, useMessageContext } from 'stream-chat-react';

import { ChatContext } from '../../utils/ChatContext';

import LLMChatBubble, { Rating } from './LLMChatBubble';

const TerraiMessageUI = () => {
  const { channel } = useContext(ChatContext);
  const { message } = useMessageContext(); // Access the current message
  const [rating, setRating] = useState<Rating>(message?.own_reactions?.[0] as unknown as Rating);

  const onRatingChange = async (_rating: Rating) => {
    try {
      setRating(_rating);
      await channel?.sendReaction(message.id, {
        type: 'rate',
        rating: _rating,
        user_id: message.user?.id,
        message_id: message.id,
      });
    } catch (error) {
      console.error('Error sending reaction:', error);
    }
  };

  // If no message is available, log and return an empty element
  if (!message) {
    console.log('No message, skipping... ', message);
    return null;
  }

  if (message.user?.role === 'admin')
    return (
      <LLMChatBubble isRateable rating={rating} onRatingChange={onRatingChange}>
        <MessageText />
      </LLMChatBubble>
    );

  return (
    <div className="str-chat__li str-chat__li--single">
      <div className="str-chat__message str-chat__message-simple str-chat__message--regular str-chat__message--received str-chat__message--me str-chat__message-simple--me str-chat__message--has-text">
        <div className="str-chat__message-inner" data-testid="message-inner">
          <div className="str-chat__message-bubble">
            <div className="str-chat__message-text">
              <div>
                <p>{message.text}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TerraiMessageUI;

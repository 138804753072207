import { IonMenu } from '@ionic/react';
import styled from 'styled-components';

const StyledIonMenu = styled(IonMenu)`
  padding-top: var(--ion-safe-area-top);
  padding-bottom: var(--ion-safe-area-bottom);
`;

export default {
  IonMenu: StyledIonMenu,
};

import { IonCardHeader, IonSkeletonText } from '@ionic/react';
import React from 'react';

import S from './PropertyCard.styles';

interface Props {
  title: React.ReactNode;
  children: React.ReactNode;
  isLoading?: boolean;
  loadingLines?: number;
}

const PropertyCard: React.FC<Props> = ({ title, children, isLoading, loadingLines = 1 }) =>
  isLoading ? (
    <S.IonCard>
      <IonCardHeader>
        <S.IonCardSubtitle>
          <IonSkeletonText animated />
        </S.IonCardSubtitle>
      </IonCardHeader>

      <S.IonCardContent>
        {Array.from(Array(loadingLines)).map((_v, i) => (
          /* eslint-disable-next-line react/no-array-index-key */
          <IonSkeletonText animated key={i} />
        ))}
      </S.IonCardContent>
    </S.IonCard>
  ) : (
    <S.IonCard>
      <IonCardHeader>
        <S.IonCardSubtitle>{title}</S.IonCardSubtitle>
      </IonCardHeader>

      <S.IonCardContent>{children}</S.IonCardContent>
    </S.IonCard>
  );

export default PropertyCard;

import { IonButton, IonSkeletonText } from '@ionic/react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';

import { environment } from '@env';

import { Address } from '../../utils/address';
import TerraiMessageInput from '../stream-chat/TerraiMessageInput';

import S from './AddressSelect.styles';

interface Props {
  onAddressSelect: (address: Address) => void;
}

export interface Suggestion {
  place_name: string;
  text: string;
  address: string;
  context: Array<{
    id: string;
    text: string;
  }>;
}

const AddressSelect: React.FC<Props> = ({ onAddressSelect }) => {
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [addressText, setAddressText] = useState<string>('');

  const { isPending, isError, isSuccess, data } = useQuery({
    enabled: addressText.length > 2,
    queryKey: ['suggestions', addressText],
    queryFn: async () => {
      const response = await axios.get<{ features: Suggestion[] }>(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
          addressText
        )}.json?access_token=${
          environment.mapboxApiKey
        }&autocomplete=true&country=us&types=address&language=en`
      );

      return response.data;
    },
  });

  useEffect(() => {
    if (isError || isPending) {
      setSuggestions([]);
      return;
    }

    if (addressText.length <= 2) return setSuggestions([]);

    if (isSuccess) return setSuggestions(data.features);
  }, [isSuccess, isError, isPending, addressText, data]);

  const onSuggestionClick = useCallback(
    async (suggestion: Suggestion) => {
      setAddressText(suggestion.place_name);
      setSuggestions([]);

      onAddressSelect({
        street: [suggestion.address, suggestion.text].filter(v => !!v).join(' '),
        city: suggestion.context.find(c => c.id.startsWith('place'))?.text || '',
        state: suggestion.context.find(c => c.id.startsWith('region'))?.text || '',
        county: suggestion.context.find(c => c.id.startsWith('district'))?.text || '',
        zipcode: suggestion.context.find(c => c.id.startsWith('postcode'))?.text || '',
        country: suggestion.context.find(c => c.id.startsWith('country'))?.text || '',
      });
    },
    [onAddressSelect]
  );

  return (
    <div>
      <TerraiMessageInput
        placeholder="Enter property address"
        value={addressText}
        onValueChange={setAddressText}
      />

      {addressText.length > 2 && suggestions.length === 0 && (
        <S.IonButtons>
          {Array.from(Array(5)).map((_v, i) => (
            /* eslint-disable-next-line react/no-array-index-key */
            <S.SuggestionWrapper key={i}>
              <IonButton expand="block" color="ocean" fill="solid">
                <IonSkeletonText animated style={{ width: '10rem' }} />
              </IonButton>
            </S.SuggestionWrapper>
          ))}
        </S.IonButtons>
      )}

      {suggestions.length > 0 && (
        <S.IonButtons>
          {suggestions.map(suggestion => (
            <S.SuggestionWrapper key={suggestion.place_name}>
              <IonButton
                onClick={() => onSuggestionClick(suggestion)}
                expand="block"
                color="ocean"
                fill="solid"
              >
                {suggestion.place_name}
              </IonButton>
            </S.SuggestionWrapper>
          ))}
        </S.IonButtons>
      )}
    </div>
  );
};

export default AddressSelect;

import { IonProgressBar } from '@ionic/react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import React, { useMemo, useState } from 'react';
import { DefaultGenerics, StreamChat } from 'stream-chat';
import 'stream-chat-react/dist/scss/v2/index.scss';

import { environment } from '@env';

import { useAuth } from '../../context/AuthProvider';
import { UserProperty } from '../../types/userProperty';
import { Address } from '../../utils/address';

import StreamChatChat from './StreamChatChat';


export interface Suggestion {
  place_name: string;
  text: string;
  address: string;
  context: Array<{
    id: string;
    text: string;
  }>;
}

const StreamChatInitializer: React.FC = () => {
  const [addressToSave, setAddressToSave] = useState<Address | null>(null);
  const [address, setAddress] = useState<Address | null>(null);

  const { user, session } = useAuth();

  const fetchAddressQuery = useQuery({
    enabled: !!session?.access_token,
    queryKey: ['address'],
    retry: false,
    queryFn: async () => {
      const response = await axios.get<Address>(`${environment.api}/address`, {
        headers: {
          Authorization: `Bearer ${session?.access_token}`,
        },
      });

      if (!response.data) throw new Error('No Address found.');

      setAddress(response.data);

      return response.data;
    },
  });

  const fetchPropertyQuery = useQuery({
    enabled: !!session?.access_token && fetchAddressQuery.isFetched && !fetchAddressQuery.data,
    queryKey: ['property'],
    retry: false,
    queryFn: async () => {
      try {
        const response = await axios.get<UserProperty>(`${environment.api}/user-property`, {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
        });

        const addressData: Address = {
          street: response.data.Property?.precisely?.matchedAddress?.mainAddressLine,
          city: response.data.Property?.city,
          state: response.data.Property?.state,
          zipcode: response.data.Property.zipcode,
          county: response.data.Property.county,
          country: response.data.Property.country,
        };

        setAddressToSave(addressData);
        setAddress(response.data.Property);

        return response.data.Property;
      } catch (err) {
        setAddress(null);

        return null;
      }
    },
  });

  const fetchChatTokenQuery = useQuery({
    enabled: !!session?.access_token,
    queryKey: ['chatToken'],
    queryFn: async () => {
      const response = await axios.get<{
        tokenOrProvider: string;
      }>(`${environment.api}/auth/chat-token`, {
        headers: {
          Authorization: `Bearer ${session?.access_token}`,
        },
      });

      return response.data.tokenOrProvider;
    },
  });

  const initChatClient = useQuery({
    enabled: !!fetchChatTokenQuery.data,
    queryKey: ['chat', fetchChatTokenQuery.data],
    queryFn: async () => {
      let didUserConnectInterrupt = false;
      const client = new StreamChat<DefaultGenerics>(environment.streamChatApiKey, undefined);

      try {
        if (!user) return;
        await client.connectUser({ id: user.id! }, fetchChatTokenQuery.data);

        if (didUserConnectInterrupt) return null;

        return client;
      } catch (err) {
        didUserConnectInterrupt = true;

        return null;
      }
    },
  });

  useQuery({
    enabled: !!session?.access_token && !!addressToSave,
    queryKey: ['saveAddress', addressToSave],
    queryFn: async () => {
      const response = await axios.post<Address>(`${environment.api}/address`, addressToSave, {
        headers: {
          Authorization: `Bearer ${session?.access_token}`,
        },
      });

      setAddress(response.data);
      setAddressToSave(null);

      return response.data;
    },
  });

  const channel = useMemo(() => {
    if (!user?.id) return null;

    if (!initChatClient.data) return null;

    return initChatClient.data.channel('messaging', `user-channel_${user.id}`, {
      name: 'Custom Channel',
      image: 'https://example.com/channel-image.jpg',
      replies: false,
      disable_notifications: true,
    });
  }, [user?.id, initChatClient.data]);

  if (!user) {
    return <IonProgressBar type="indeterminate" />;
  }

  if (!initChatClient.data) {
    return <IonProgressBar type="indeterminate" />;
  }

  if (!channel) {
    return <IonProgressBar type="indeterminate" />;
  }

  if (!fetchAddressQuery.isFetched) {
    return <IonProgressBar type="indeterminate" />;
  }

  if (!address && !fetchPropertyQuery.isFetched) {
    return <IonProgressBar type="indeterminate" />;
  }

  return (
    <StreamChatChat
      client={initChatClient.data}
      channel={channel}
      address={address}
      onSaveAddress={setAddressToSave}
    />
  );
};

export default StreamChatInitializer;

import styled from 'styled-components';

import PropertyCardStyles from './PropertyCard.styles';

const CardsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 1rem;

  ${PropertyCardStyles.IonCard} {
    &:nth-child(1) {
      grid-area: 1 / 1 / 5 / 3;
    }

    &:nth-child(2) {
      grid-area: 1 / 3 / 3 / 4;
    }

    &:nth-child(3) {
      grid-area: 1 / 4 / 2 / 5;
    }

    &:nth-child(4) {
      grid-area: 2 / 4 / 3 / 5;
    }

    &:nth-child(5) {
      grid-area: 3 / 3 / 5 / 4;
    }

    &:nth-child(6) {
      grid-area: 3 / 4 / 5 / 5;
    }
  }
`;

export default {
  CardsGrid,
};

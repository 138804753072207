import { IonCol, IonGrid, IonRow, IonImg } from '@ionic/react';
import React from 'react';

import S from './AboutUsPage.styles';
import TextPage from './common/TextPage';


interface SectionProps {
  title: string;
  UnderTitle?: React.ReactNode;
  children: React.ReactNode;
}
const Section: React.FC<SectionProps> = ({ title, UnderTitle, children }) => (
  <IonGrid>
    <IonRow>
      <IonCol size="12" sizeLg="4">
        <h3>{title}</h3>
        {UnderTitle}
      </IonCol>

      <IonCol size="auto">
        <IonImg className="ion-hide-lg-down" src="/assets/images/divider.svg" alt="|" />
      </IonCol>

      <IonCol>{children}</IonCol>
    </IonRow>
  </IonGrid>
);

const AboutUsPage: React.FC = () => (
  <TextPage>
    <Section
      title="About Us"
      UnderTitle={
        <>
          <S.IonButton color="ocean" disabled>
            Find & Understand Property
          </S.IonButton>
          <br />
          <S.IonButton color="ocean" disabled>
            Actionable Information
          </S.IonButton>
        </>
      }
    >
      <p>
        Welcome to Terr.ai, where we believe that every homeowner deserves clear, accessible, and
        actionable real estate information. Our journey began with a simple idea: to harness the
        power of AI to eliminate the frustration that comes with finding and understanding
        property-related information.
      </p>
    </Section>

    <Section title="Our Mission">
      <p>
        At Terr.ai, our mission is to democratize real estate knowledge through cutting-edge AI
        technology. We’re here to bridge the gap between complex legal documents and everyday
        homeowners, turning data into insights that are easy to understand and act upon. By
        simplifying the search for information and translating legalese into plain language, we
        empower you to make informed decisions about your property.
      </p>
    </Section>

    <Section title="Why We Exist">
      <p>
        Navigating the complexities of real estate and homeownership can be overwhelming, with
        lengthy HOA documents, conflicting local regulations, confusing legal terms, and the stress
        of home renovations, repairs, and maintenance. That's where we come in. Our AI-powered
        platform is your personalized guide, simplifying every aspect of homeownership. We provide
        tailored, relevant information specific to your home and needs—not just generic advice. With
        us, you'll find it easier to understand, manage, and enhance your homeownership experience.
      </p>
    </Section>

    <Section title="Join Us">
      <p>
        Whether you’re a first-time homeowner, a seasoned property owner, or part of an HOA
        community, Terr.ai is here to support you. Together, with the power of AI, we can make real
        estate less about confusion and more about confidence.
      </p>
    </Section>

    <Section title="Got Questions? We’ve Got Answers!">
      <p>
        We're here to help with all your township inquiries. Whether you need information, have a
        suggestion, or just want to chat, don’t hesitate to reach out at{' '}
        <a href="mailto:hello@terr.ai">hello@terr.ai</a>!
      </p>
    </Section>
  </TextPage>
);

export default AboutUsPage;

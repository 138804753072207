import React from 'react';

import TextPage from './common/TextPage';

const TermsOfServicePage: React.FC = () => (
  <TextPage>
    <h1>TERR.AI Terms Of Service</h1>
    <p>
      <em>Last Updated: 19 Aug 2024</em>
    </p>

    <p>
      Please read these Terms of Service (the "Agreement") carefully. By clicking ["Accept" below]
      or otherwise using the Site or the Service (each as defined below) you consent to this
      Agreement.
    </p>
    <p>
      This Agreement is between you and Terr.ai LIS LLC ("Company" or "we" or "us") concerning your
      use of (including any access to) the Terr.ai site currently located at https://terr.ai
      (together with any materials and services available therein, including the Service (as defined
      below) and successor site(s) thereto, the "Site"). This Agreement hereby incorporates by this
      reference any additional terms and conditions posted by Company through the Site, or otherwise
      made available to you by Company.
    </p>
    <p>
      BY USING THE SITE, YOU AFFIRM THAT YOU ARE OF LEGAL AGE TO ENTER INTO THIS AGREEMENT OR, IF
      YOU ARE NOT, THAT YOU HAVE OBTAINED PARENTAL OR GUARDIAN CONSENT TO ENTER INTO THIS AGREEMENT.
    </p>
    <p>
      THIS AGREEMENT CONTAINS A MANDATORY ARBITRATION PROVISION THAT, AS FURTHER SET FORTH IN
      SECTION 14 BELOW, REQUIRES THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES,
      RATHER THAN JURY TRIALS OR ANY OTHER COURT PROCEEDINGS, OR CLASS ACTIONS OF ANY KIND.
    </p>

    <h2>1. Changes</h2>
    <p>
      We may change this Agreement from time to time by notifying you of such changes by any
      reasonable means, including by posting a revised Agreement through the Site. Any such changes
      will not apply to any dispute between you and us arising prior to the date on which we posted
      the revised Agreement incorporating such changes, or otherwise notified you of such changes.
    </p>
    <p>
      Your use of the Site following any changes to this Agreement will constitute your acceptance
      of such changes. The "Last Updated" legend above indicates when this Agreement was last
      changed. We may, at any time and without liability, modify or discontinue all or part of the
      Site (including access to the Site via any third-party links); charge, modify or waive any
      fees required to use the Site; or offer opportunities to some or all Site users.
    </p>

    <h2>2. Information Submitted Through the Site</h2>
    <p>
      Your submission of information through the Site is governed by Company's Privacy Policy,
      located at https://app.terr.ai/privacy (the "Privacy Policy"). You represent and warrant that
      any information you provide in connection with the Site is and will remain accurate and
      complete, and that you will maintain and update such information as needed.
    </p>

    <h2>3. Jurisdictional Issues</h2>
    <p>
      The Site is controlled or operated (or both) from the United States, and is not intended to
      subject Company to any non-U.S. jurisdiction or law. The Site may not be appropriate or
      available for use in some non-U.S. jurisdictions. Any use of the Site is at your own risk, and
      you must comply with all applicable laws, rules and regulations in doing so. We may limit the
      Site's availability at any time, in whole or in part, to any person, geographic area or
      jurisdiction that we choose.
    </p>

    <h2>4. Your Limited Rights</h2>
    <p>
      Subject to your compliance with this Agreement, and solely for so long as you are permitted by
      Company to use the Site, you may view one (1) copy of any portion of the Site to which we
      provide you access under this Agreement, on any single device, solely for your personal,
      non-commercial use.
    </p>

    <h2>5. Service</h2>
    <h3>5.1. Eligibility</h3>
    <p>
      Through the Site, Company makes available a chat service powered by generative artificial
      intelligence that can be accessed by users in certain geographic locations (the "Service"). In
      order to access the Service, you must enter your home address and, subject to your compliance
      with this Agreement, if your address is in a covered geographic location, you may register for
      an account to access and use the Services in accordance with this Agreement solely for your
      personal, non-commercial use. If your address is not in a covered location, you shall not
      access or use the Services.
    </p>

    <h3>5.2. Registration</h3>
    <p>
      You must register in order to access or use the Service. We may reject, or require that you
      change, any username, password or other information that you provide to us in registering.
      Your username and password are for your personal use only and should be kept confidential;
      you, and not Company, are responsible for any use or misuse of your username or password, and
      you must promptly notify us of any confidentiality breach or unauthorized use of your username
      or password, or your Site account.
    </p>

    <h3>5.3. Content</h3>
    <p>
      You may provide input to the Service, including questions regarding your geographic location
      ("Input"), and receive output from the Services based on the Input ("Output"). Input and
      Output are collectively "Content". You are responsible for Content, including for ensuring
      that it does not violate any applicable law or these Terms. You represent and warrant that you
      have all rights, licenses, and permissions needed to provide Input to our Services. As between
      you and Company, you retain your ownership rights in Input. Due to the nature of the Service
      and artificial intelligence generally, Output may not be unique and other users may receive
      similar output from the Service. If you choose to make any of your information publicly
      available through the Service or otherwise, you do so at your own risk.
    </p>

    <h3>5.4. License</h3>
    <p>
      By submitting Input to the Service, you hereby grant to Company a worldwide, perpetual,
      irrevocable, transferable, sublicensable (through multiple tiers), non-exclusive right and
      license to reproduce, perform and display (publicly or otherwise), edit, improve, modify,
      adapt, publish, translate, create derivative works based on, distribute and otherwise use such
      Input in connection with the Site and Service, including to provide, develop, maintain and
      improve the Service and to develop new services and products.
    </p>

    <h3>5.5. Disclaimers</h3>
    <p>
      WITHOUT LIMITING THE GENERALITY OF THE DISCLAIMERS SET FORTH IN SECTION 10 BELOW, YOU HEREBY
      ACKNOWLEDGE AND AGREE: (I) OUTPUT MAY NOT ALWAYS BE ACCURATE AND YOU SHOULD NOT RELY ON OUTPUT
      FROM THE SERVICE AS A SOLE SOURCE OF TRUTH OR FACTUAL INFORMATION, OR AS A SUBSTITUTE FOR
      PROFESSIONAL ADVICE: (II) YOU WILL EVALUATE OUTPUT FOR ACCURACY AND APPROPRIATENESS FOR YOUR
      USE CASE, INCLUDING USING HUMAN REVIEW AS APPROPRIATE, BEFORE USING OR SHARING OUTPUT; (III)
      YOU WILL NOT USE ANY OUTPUT RELATING TO A PERSON FOR ANY PURPOSE THAT COULD HAVE A LEGAL OR
      MATERIAL IMPACT ON THAT PERSON, SUCH AS MAKING CREDIT, EDUCATIONAL, EMPLOYMENT, HOUSING,
      INSURANCE, LEGAL, MEDICAL, OR OTHER IMPORTANT DECISIONS ABOUT THEM; AND (IV) THE SERVICE MAY
      PROVIDE INCOMPLETE, INCORRECT OR OFFENSIVE OUTPUT THAT DOES NOT REPRESENT COMPANY'S VIEWS. IF
      OUTPUT REFERENCES ANY THIRD PARTY PRODUCTS OR SERVICES, IT DOES NOT MEAN THAT THE THIRD PARTY
      ENDORSES OR IS AFFILIATED WITH COMPANY,
    </p>

    <h2>6. Rules of Conduct</h2>
    <p>In connection with the Site, you must not:</p>
    <ul>
      <li>
        Post, transmit or otherwise make available through or in connection with the Site any
        materials that are or may be: (a) threatening, harassing, degrading, hateful or
        intimidating, or otherwise fail to respect the rights and dignity of others; (b) defamatory,
        libelous, fraudulent or otherwise tortious; (c) obscene, indecent, pornographic or otherwise
        objectionable; or (d) protected by copyright, trademark, trade secret, right of publicity or
        privacy or any other proprietary right, without the express prior written consent of the
        applicable owner.
      </li>
      <li>
        Post, transmit or otherwise make available through or in connection with the Site any virus,
        worm, Trojan horse, Easter egg, time bomb, spyware or other computer code, file or program
        that is or is potentially harmful or invasive or intended to damage or hijack the operation
        of, or to monitor the use of, any hardware, software or equipment (each, a "Virus").
      </li>
      <li>
        Use the Site for any commercial purpose, or for any purpose that is fraudulent or otherwise
        tortious or unlawful.
      </li>
      <li>Harvest or collect information about users of the Site.</li>
      <li>
        Interfere with or disrupt the operation of the Site or the servers or networks used to make
        the Site available, including by hacking or defacing any portion of the Site; or violate any
        requirement, procedure or policy of such servers or networks.
      </li>
      <li>Restrict or inhibit any other person from using the Site.</li>
      <li>
        Reproduce, modify, adapt, translate, create derivative works of, sell, rent, lease, loan,
        timeshare, distribute or otherwise exploit any portion of (or any use of) the Site except as
        expressly authorized herein, without Company's express prior written consent.
      </li>
      <li>
        Reverse engineer, decompile or disassemble any portion of the Site, except where such
        restriction is expressly prohibited by applicable law.
      </li>
      <li>Remove any copyright, trademark or other proprietary rights notice from the Site.</li>
      <li>
        Frame or mirror any portion of the Site, or otherwise incorporate any portion of the Site
        into any product or service, without Company's express prior written consent.
      </li>
      <li>Systematically download and store Site content.</li>
      <li>
        Use any robot, spider, site search/retrieval application or other manual or automatic device
        to retrieve, index, "scrape," "data mine" or otherwise gather Site content, or reproduce or
        circumvent the navigational structure or presentation of the Site, without Company's express
        prior written consent. Notwithstanding the foregoing, and subject to compliance with any
        instructions posted in the robots.txt file located in the Site's root directory, Company
        grants to the operators of public search engines permission to use spiders to copy materials
        from the Site for the sole purpose of (and solely to the extent necessary for) creating
        publicly available, searchable indices of such materials, but not caches or archives of such
        materials. Company reserves the right to revoke such permission either generally or in
        specific cases, at any time and without notice.
      </li>
    </ul>
    <p>
      You are responsible for obtaining, maintaining and paying for all hardware and all
      telecommunications and other services needed to use the Site.
    </p>

    <h2>7. Feedback</h2>
    <p>
      In addition, if you provide to us any ideas, proposals, suggestions or other materials
      ("Feedback"), whether related to the Site or otherwise, you hereby acknowledge and agree that
      such Feedback is not confidential, and that your provision of such Feedback is gratuitous,
      unsolicited and without restriction, and does not place Company under any fiduciary or other
      obligation.
    </p>
    <p>
      You represent and warrant that you have all rights necessary to grant the licenses granted in
      this section, and that your Feedback, and your provision thereof through and in connection
      with the Site, are complete and accurate, and are not fraudulent, tortious or otherwise in
      violation of any applicable law or any right of any third party. You further irrevocably waive
      any "moral rights" or other rights with respect to attribution of authorship or integrity of
      materials regarding any Feedback that you may have under any applicable law under any legal
      theory.
    </p>

    <h2>8. Company's Proprietary Rights</h2>
    <p>
      We and our suppliers own the Site, which is protected by proprietary rights and laws. Our
      trade names, trademarks and service marks include TERR.AI and any associated logos. All trade
      names, trademarks, service marks and logos on the Site not owned by us are the property of
      their respective owners. You may not use our trade names, trademarks, service marks or logos
      in connection with any product or service that is not ours, or in any manner that is likely to
      cause confusion. Nothing contained on the Site should be construed as granting any right to
      use any trade names, trademarks, service marks or logos without the express prior written
      consent of the owner.
    </p>

    <h2>9. Third Party Materials; Links</h2>
    <p>
      Certain Site functionality may make available access to information, products, services and
      other materials made available by third parties, including Submissions ("Third Party
      Materials"), or allow for the routing or transmission of such Third Party Materials, including
      via links. By using such functionality, you are directing us to access, route and transmit to
      you the applicable Third Party Materials.
    </p>
    <p>
      We neither control nor endorse, nor are we responsible for, any Third Party Materials,
      including the accuracy, validity, timeliness, completeness, reliability, integrity, quality,
      legality, usefulness or safety of Third Party Materials, or any intellectual property rights
      therein. Certain Third Party Materials may, among other things, be inaccurate, misleading or
      deceptive. Nothing in this Agreement shall be deemed to be a representation or warranty by
      Company with respect to any Third Party Materials. We have no obligation to monitor Third
      Party Materials, and we may block or disable access to any Third Party Materials (in whole or
      part) through the Site at any time. In addition, the availability of any Third Party Materials
      through the Site does not imply our endorsement of, or our affiliation with, any provider of
      such Third Party Materials, nor does such availability create any legal relationship between
      you and any such provider.
    </p>
    <p>
      YOUR USE OF THIRD PARTY MATERIALS IS AT YOUR OWN RISK AND IS SUBJECT TO ANY ADDITIONAL TERMS,
      CONDITIONS AND POLICIES APPLICABLE TO SUCH THIRD PARTY MATERIALS (SUCH AS TERMS OF SERVICE OR
      PRIVACY POLICIES OF THE PROVIDERS OF SUCH THIRD PARTY MATERIALS).
    </p>

    <h2>10. DISCLAIMER OF WARRANTIES</h2>
    <p>
      TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW: (A) THE SITE AND THIRD PARTY MATERIALS
      ARE MADE AVAILABLE TO YOU ON AN "AS IS," "WHERE IS" AND "WHERE AVAILABLE" BASIS, WITHOUT ANY
      WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED OR STATUTORY; AND (B) COMPANY DISCLAIMS ALL
      WARRANTIES WITH RESPECT TO THE SITE AND THIRD PARTY MATERIALS, INCLUDING THE WARRANTIES OF
      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT AND TITLE. ALL DISCLAIMERS
      OF ANY KIND (INCLUDING IN THIS SECTION AND ELSEWHERE IN THIS AGREEMENT) ARE MADE FOR THE
      BENEFIT OF BOTH COMPANY AND [ITS AFFILIATES AND THEIR RESPECTIVE DIRECTORS, OFFICERS,
      EMPLOYEES, AFFILIATES, AGENTS, REPRESENTATIVES, LICENSORS, SUPPLIERS AND SERVICE PROVIDERS]
      (COLLECTIVELY, THE "AFFILIATED ENTITIES"), AND THEIR RESPECTIVE SUCCESSORS AND ASSIGNS.
    </p>
    <p>
      While we try to maintain the timeliness, integrity and security of the Site, we do not
      guarantee that the Site is or will remain updated, complete, correct or secure, or that access
      to the Site will be uninterrupted. The Site may include inaccuracies, errors and materials
      that violate or conflict with this Agreement. Additionally, third parties may make
      unauthorized alterations to the Site. If you become aware of any such alteration, contact us
      at hello@terr.ai with a description of such alteration and its location on the Site.
    </p>

    <h2>11. LIMITATION OF LIABILITY</h2>
    <p>
      TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW: (A) COMPANY WILL NOT BE LIABLE FOR ANY
      INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY OR PUNITIVE DAMAGES OF ANY KIND, UNDER
      ANY CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHER THEORY, INCLUDING DAMAGES
      FOR LOSS OF PROFITS, USE OR DATA, LOSS OF OTHER INTANGIBLES, EVEN IF ADVISED IN ADVANCE OF THE
      POSSIBILITY OF SUCH DAMAGES OR LOSSES; (B) WITHOUT LIMITING THE FOREGOING, COMPANY WILL NOT BE
      LIABLE FOR DAMAGES OF ANY KIND RESULTING FROM YOUR USE OF OR INABILITY TO USE THE SITE OR
      THIRD PARTY MATERIALS, INCLUDING FROM ANY VIRUS THAT MAY BE TRANSMITTED IN CONNECTION
      THEREWITH; (C) YOUR SOLE AND EXCLUSIVE REMEDY FOR DISSATISFACTION WITH THE SITE OR ANY
      PRODUCTS OR THIRD PARTY MATERIALS IS TO STOP USING THE SITE; AND (D) THE MAXIMUM AGGREGATE
      LIABILITY OF COMPANY FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION, WHETHER IN CONTRACT, TORT
      (INCLUDING NEGLIGENCE) OR OTHERWISE, SHALL BE ONE HUNDRED U.S. DOLLARS ($100.00). ALL
      LIMITATIONS OF LIABILITY OF ANY KIND (INCLUDING IN THIS SECTION AND ELSEWHERE IN THIS
      AGREEMENT) ARE MADE FOR THE BENEFIT OF BOTH COMPANY AND THE AFFILIATED ENTITIES, AND THEIR
      RESPECTIVE SUCCESSORS AND ASSIGNS.
    </p>

    <h2>12. Indemnity</h2>
    <p>
      To the fullest extent permitted under applicable law, you agree to defend, indemnify and hold
      harmless Company and the Affiliated Entities, and their respective successors and assigns,
      from and against all claims, liabilities, damages, judgments, awards, losses, costs, expenses
      and fees (including attorneys' fees) arising out of or relating to (a) your use of, or
      activities in connection with, the Site (including your use of the Services and any Content);
      (b) your Inputs and any Outputs based on such Inputs; and (c) any violation or alleged
      violation of this Agreement by you.
    </p>

    <h2>13. Termination</h2>
    <p>
      This Agreement is effective until terminated. Company may terminate or suspend your use of the
      Site at any time and without prior notice, for any or no reason, including if Company believes
      that you have violated or acted inconsistently with the letter or spirit of this Agreement.
      Upon any such termination or suspension, your right to use the Site will immediately cease,
      and Company may, without liability to you or any third party, immediately deactivate or delete
      your user name, password and account, and all associated materials, without any obligation to
      provide any further access to such materials. Sections 2, 3, 5.4, 5.5, 6–18 and 20 shall
      survive any expiration or termination of this Agreement.
    </p>

    <h2>14. Governing Law</h2>
    <p>
      The terms of this Agreement are governed by the laws of the United States (including federal
      arbitration law) and the state of New York without regard to its principles of conflicts of
      law, and regardless of your location. EXCEPT FOR DISPUTES THAT QUALIFY FOR SMALL CLAIMS COURT,
      ALL DISPUTES ARISING OUT OF OR RELATED TO THIS AGREEMENT OR ANY ASPECT OF THE RELATIONSHIP
      BETWEEN YOU AND COMPANY, WHETHER BASED IN CONTRACT, TORT, STATUTE, FRAUD, MISREPRESENTATION OR
      ANY OTHER LEGAL THEORY, WILL BE RESOLVED THROUGH FINAL AND BINDING ARBITRATION BEFORE A
      NEUTRAL ARBITRATOR INSTEAD OF IN A COURT BY A JUDGE OR JURY AND YOU AGREE THAT COMPANY AND YOU
      ARE EACH WAIVING THE RIGHT TO TRIAL BY A JURY. EXCEPT AS PROVIDED BELOW REGARDING THE CLASS
      ACTION WAIVER, SUCH DISPUTES INCLUDE, WITHOUT LIMITATION, DISPUTES ARISING OUT OF OR RELATING
      TO INTERPRETATION OR APPLICATION OF THIS ARBITRATION PROVISION, INCLUDING THE ENFORCEABILITY,
      REVOCABILITY OR VALIDITY OF THE ARBITRATION PROVISION OR ANY PORTION OF THE ARBITRATION
      PROVISION. ALL SUCH MATTERS SHALL BE DECIDED BY AN ARBITRATOR AND NOT BY A COURT OR JUDGE.
      HOWEVER, AS SET FORTH BELOW, THE PRECEDING ARBITRATION REQUIREMENT SHALL NOT APPLY TO DISPUTES
      TO THE EXTENT RELATING TO THE INTERPRETATION OR APPLICATION OF THE CLASS ACTION WAIVER BELOW,
      INCLUDING ITS ENFORCEABILITY, REVOCABILITY OR VALIDITY.
    </p>
    <p>
      YOU AGREE THAT ANY ARBITRATION UNDER THIS AGREEMENT WILL TAKE PLACE ON AN INDIVIDUAL BASIS;
      CLASS ARBITRATIONS AND CLASS ACTIONS ARE NOT PERMITTED AND YOU ARE AGREEING TO GIVE UP THE
      ABILITY TO PARTICIPATE IN A CLASS ACTION. Notwithstanding anything to the contrary in this
      Section or any other provision of this Agreement or in the American Arbitration Association's
      Consumer Arbitration Rules, disputes regarding the enforceability, revocability or validity of
      the foregoing class action waiver may be resolved only by a civil court of competent
      jurisdiction and not by an arbitrator. In any case in which (1) the dispute is filed as a
      class, collective, or representative action, and (2) there is a final judicial determination
      that all or part of such class action waiver is unenforceable, then the class, collective,
      and/or representative action, to that extent, must be litigated in a civil court of competent
      jurisdiction, but the portion of such class action waiver that is enforceable shall be
      enforced in arbitration.
    </p>
    <p>
      The arbitration will be administered by the American Arbitration Association under its
      Consumer Arbitration Rules, as amended by this Agreement. The Consumer Arbitration Rules are
      available online at https://www.adr.org/sites/default/files/Consumer_Rules_Web_2.pdf. The
      arbitrator will conduct hearings, if any, by teleconference or videoconference, rather than by
      personal appearances, unless the arbitrator determines upon request by you or by us that an
      in-person hearing is appropriate. Any in-person appearances will be held at a location which
      is reasonably convenient to both parties with due consideration of their ability to travel and
      other pertinent circumstances. If the parties are unable to agree on a location, such
      determination should be made by the AAA or by the arbitrator. The arbitrator's decision will
      follow the terms of this Agreement and will be final and binding. The arbitrator will have
      authority to award temporary, interim or permanent injunctive relief or relief providing for
      specific performance of this Agreement, but only to the extent necessary to provide relief
      warranted by the individual claim before the arbitrator. The award rendered by the arbitrator
      may be confirmed and enforced in any court having jurisdiction thereof. Notwithstanding any of
      the foregoing, nothing in this Agreement will preclude you from bringing issues to the
      attention of federal, state or local agencies and, if the law allows, they can seek relief
      against us for you.
    </p>

    <h2>15. New Jersey Consumers</h2>
    <p>
      If you are a consumer residing in New Jersey, the following provisions of this Agreement do
      not apply to you (and do not limit any rights that you may have) to the extent that they are
      unenforceable under New Jersey law: (a) the disclaimer of liability for any indirect,
      incidental, consequential, special, exemplary or punitive damages of any kind (for example, to
      the extent unenforceable under the New Jersey Punitive Damages Act, New Jersey Products
      Liability Act, New Jersey Uniform Commercial Code and New Jersey Consumer Fraud Act); (b) the
      limitations of liability for lost profits or loss or misuse of any data (for example, to the
      extent unenforceable under the New Jersey Identity Theft Protection Act and New Jersey
      Consumer Fraud Act); (c) application of the limitations of liability to the recovery of
      damages that arise under contract and tort, including negligence, strict liability or any
      other theory (for example, to the extent such damages are recoverable by a consumer under New
      Jersey law, including the New Jersey Products Liability Act); (d) the requirement that you
      indemnify Company and the Indemnified Parties (for example, to the extent the scope of such
      indemnity is prohibited under New Jersey law); and (e) the New York governing law provision
      (for example, to the extent that your rights as a consumer residing in New Jersey are required
      to be governed by New Jersey law).
    </p>

    <h2>16. Filtering</h2>
    <p>
      We hereby notify you that parental control protections (such as computer hardware, software or
      filtering services) may be commercially available to assist you in limiting access to material
      that is harmful to minors. Information identifying current providers of such protections
      should be available at
      https://en.wikipedia.org/wiki/Comparison_of_content-control_software_and_providers. Please
      note that Company does not endorse any of the products or services listed on such site.
    </p>

    <h2>17. Information or Complaints</h2>
    <p>
      If you have a question or complaint regarding the Site, please send an e-mail to
      hello@terr.ai. You may also contact us by writing to PO Box 3067, Princeton NJ 08543. Please
      note that e-mail communications will not necessarily be secure; accordingly you should not
      include credit card information or other sensitive information in your e-mail correspondence
      with us.
    </p>

    <h2>18. Copyright Infringement Claims</h2>
    <p>
      The Digital Millennium Copyright Act of 1998 (the "DMCA") provides recourse for copyright
      owners who believe that material appearing on the Internet infringes their rights under U.S.
      copyright law. If you believe in good faith that materials available on the Site infringe your
      copyright, you (or your agent) may send to Company a written notice by mail or e-mail,
      requesting that Company remove such material or block access to it. If you believe in good
      faith that someone has wrongly filed a notice of copyright infringement against you, the DMCA
      permits you to send to Company a counter-notice. Notices and counter-notices must meet the
      then-current statutory requirements imposed by the DMCA. See http://www.copyright.gov/ for
      details. Notices and counter-notices must be sent in writing to Daniel F as follows: By mail
      to Daniel F, PO Box 3067, Princeton NJ 08543, or by e-mail to hello@terr.ai.
    </p>
    <p>
      We suggest that you consult your legal advisor before filing a DMCA notice or counter-notice.
    </p>

    <h2>19. Export Controls</h2>
    <p>
      You are responsible for complying with United States export controls and for any violation of
      such controls, including any United States embargoes or other federal rules and regulations
      restricting exports. You represent, warrant and covenant that you are not (a) located in, or a
      resident or a national of, any country subject to a U.S. government embargo or other
      restriction, or that has been designated by the U.S. government as a "terrorist supporting"
      country; or (b) on any of the U.S. government lists of restricted end users.
    </p>

    <h2>20. Miscellaneous</h2>
    <p>
      This Agreement does not, and shall not be construed to, create any partnership, joint venture,
      employer-employee, agency or franchisor-franchisee relationship between you and Company. If
      any provision of this Agreement is found to be unlawful, void or for any reason unenforceable,
      that provision will be deemed severable from this Agreement and will not affect the validity
      and enforceability of any remaining provision. You may not assign, transfer or sublicense any
      or all of your rights or obligations under this Agreement without our express prior written
      consent. We may assign, transfer or sublicense any or all of our rights or obligations under
      this Agreement without restriction. No waiver by either party of any breach or default under
      this Agreement will be deemed to be a waiver of any preceding or subsequent breach or default.
      Any heading, caption or section title contained herein is for convenience only, and in no way
      defines or explains any section or provision. All terms defined in the singular shall have the
      same meanings when used in the plural, where appropriate and unless otherwise specified. Any
      use of the term "including" or variations thereof in this Agreement shall be construed as if
      followed by the phrase "without limitation." This Agreement, including any terms and
      conditions incorporated herein, is the entire Agreement between you and Company relating to
      the subject matter hereof, and supersedes any and all prior or contemporaneous written or oral
      Agreements or understandings between you and Company relating to such subject matter. Notices
      to you (including notices of changes to this Agreement) may be made via posting to the Site or
      by e-mail (including in each case via links), or by regular mail. Without limitation, a
      printed version of this Agreement and of any notice given in electronic form shall be
      admissible in judicial or administrative proceedings based upon or relating to this Agreement
      to the same extent and subject to the same conditions as other business documents and records
      originally generated and maintained in printed form. Company will not be responsible for any
      failure to fulfill any obligation due to any cause beyond its control.
    </p>
  </TextPage>
);

export default TermsOfServicePage;

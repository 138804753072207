import { IonCol, IonRow } from '@ionic/react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { environment } from '@env';

import PropertyDetails from '../components/property/PropertyDetails';
import { Routes } from '../config/routes';
import { useAuth } from '../context/AuthProvider';
import { UserProperty } from '../types/userProperty';

import BasePage from './common/BasePage';

export interface DashboardPageState {
  userProperty?: UserProperty;
}

const DashboardPage: React.FC = () => {
  const { session } = useAuth();
  const history = useHistory();

  const location = useLocation<DashboardPageState>();

  const getUserPropertyQuery = useQuery({
    enabled: !!session?.access_token && !location.state?.userProperty?.Property,
    queryKey: ['dashboard', session?.access_token],
    queryFn: async () => {
      const response = await axios.get<UserProperty>(`${environment.api}/user-property`, {
        headers: {
          Authorization: `Bearer ${session?.access_token}`,
        },
      });

      if (!response.data) history.replace(Routes.createProperty);

      return response.data;
    },
  });

  const property = useMemo(
    () => location.state?.userProperty?.Property || getUserPropertyQuery.data?.Property,
    [getUserPropertyQuery.data, location.state?.userProperty?.Property]
  );

  return (
    <BasePage>
      <IonRow>
        <IonCol
          sizeXl="8"
          sizeLg="8"
          offsetXl="2"
          offsetLg="2"
          sizeMd="10"
          offsetMd="1"
          sizeSm="12"
        >
          <PropertyDetails property={property} isLoading={!property} />
        </IonCol>
      </IonRow>
    </BasePage>
  );
};

export default DashboardPage;

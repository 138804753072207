// Dashboard.tsx
import { IonButton, IonCol, IonContent, IonGrid, IonPage, IonRow } from '@ionic/react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { environment } from '@env';

import AddressSelect from '../components/address/AddressSelect';
import ContentGradient from '../components/ContentGradient';
import Header from '../components/Header';
import PropertyDetails from '../components/property/PropertyDetails';
import LLMChatBubble from '../components/stream-chat/LLMChatBubble';
import { Routes } from '../config/routes';
import { useAuth } from '../context/AuthProvider';
import { Property } from '../types/property';
import { UserProperty } from '../types/userProperty';
import { addressToText } from '../utils/address';

import S from './PropertyCreatePage.styled';

const PropertyCreatePage: React.FC = () => {
  const [addressText, setAddressText] = useState<string | null>(null);
  const [hasFailed, setHasFailed] = useState(false);

  const { session } = useAuth();

  const history = useHistory();

  const getPropertyQuery = useQuery({
    enabled: !!session?.access_token && !!addressText,
    queryKey: ['property', addressText],
    retry: false,
    queryFn: async () => {
      setHasFailed(false);
      const response = await axios.post<Property>(
        `${environment.api}/property/byaddress?address=${addressText}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
        }
      );

      return response.data;
    },
  });

  const savePropertyQuery = useQuery({
    enabled: false,
    queryKey: ['save', 'property', addressText],
    queryFn: async () => {
      const response = await axios.post<UserProperty>(
        `${environment.api}/user-property/byaddress?address=${addressText}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
        }
      );

      history.replace(Routes.dashboard, {
        userProperty: response.data,
      });

      return response.data;
    },
  });

  useEffect(() => {
    if (!getPropertyQuery.error) return;

    setAddressText(null);
    setHasFailed(true);
  }, [getPropertyQuery.error]);

  const onDiscardClick = useCallback(() => {
    setAddressText(null);
  }, []);

  const onSaveClick = useCallback(() => {
    savePropertyQuery.refetch();
  }, [savePropertyQuery]);

  return (
    <IonPage>
      <IonContent>
        <ContentGradient />

        <IonGrid>
          <IonRow>
            <IonCol>
              <Header />
            </IonCol>
          </IonRow>
        </IonGrid>

        {!addressText && (
          <IonGrid>
            <IonRow>
              <IonCol
                sizeXl="6"
                sizeLg="6"
                offsetXl="3"
                offsetLg="3"
                sizeMd="10"
                offsetMd="1"
                sizeSm="12"
              >
                <S.ChatWrapper>
                  <LLMChatBubble>
                    Let&apos;s find your property! Provide a full address, including building and
                    apartment numbers.
                  </LLMChatBubble>

                  <AddressSelect
                    onAddressSelect={address => setAddressText(addressToText(address))}
                  />

                  {hasFailed && (
                    <LLMChatBubble>
                      We couldn&apos;t find property information for provided address. Try a
                      different address.
                    </LLMChatBubble>
                  )}
                </S.ChatWrapper>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}

        {!!addressText && !getPropertyQuery.isError && (
          <IonGrid>
            <IonRow>
              <IonCol
                sizeXl="8"
                sizeLg="8"
                offsetXl="2"
                offsetLg="2"
                sizeMd="10"
                offsetMd="1"
                sizeSm="12"
              >
                <PropertyDetails
                  property={getPropertyQuery.data}
                  isLoading={getPropertyQuery.isPending}
                />

                {getPropertyQuery.isSuccess && (
                  <div>
                    <S.ButtonsWrapper>
                      <IonButton color="darkgray" fill="outline" onClick={() => onDiscardClick()}>
                        Discard
                      </IonButton>

                      <IonButton
                        color="darkgray"
                        fill="solid"
                        disabled={savePropertyQuery.isRefetching}
                        onClick={() => onSaveClick()}
                      >
                        Save Property
                      </IonButton>
                    </S.ButtonsWrapper>
                  </div>
                )}
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      </IonContent>
    </IonPage>
  );
};

export default PropertyCreatePage;

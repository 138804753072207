import React from 'react';

import S from './ContentGradient.styles';

const ContentGradient: React.FC = () => (
  <S.Container>
    <S.Gradient />
    <S.Solid />
  </S.Container>
);

export default ContentGradient;
